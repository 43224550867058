@import '@/core/tamagoshi/scss';
@import '../../../styles/commons';

.button {
  margin: 0;
  padding: 0 $spacingXs;

  cursor: pointer;
  text-align: left;

  @include buttonDefault;

  &:hover {
    @include buttonHover;
  }

  &:focus {
    @include buttonFocus;
  }

  &:active {
    @include buttonPressed;
  }

  &:disabled {
    @include buttonDisabled;
  }
}

.link {
  color: $n0;
  text-decoration: none;

  display: flex;
  align-items: center;
  gap: $spacingXs;
}

.logo {
  height: 24px;
  width: 24px;
}

.labels {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  height: 40px;
}

.title {
  @include text('small', 'regular');
}

.offerText {
  @include text('small', 'regular');
}
