@use '/src/domains/core/tamagoshiv2/tokens/colors';

@import '@/core/tamagoshi/scss';

// NOTE These semantics Z index levels relate to how elements within the header
//      behave against one another. As long as they are used within the header
//      stacking context, they won't tamper with Tamagoshi values.
$headerLeonidasZIndexRoot: $zIndexClickTriggeredContent;
$headerLeonidasZIndexBase: 10;
$headerLeonidasZIndexOverlay: 20;
$headerLeonidasZIndexBanner: 30;
$headerLeonidasZIndexAccount: 40;
$headerLeonidasZIndexSearch: 50;
$headerLeonidasZIndexMenu: 60;

$headerLiteHeight: 76px;
$mobileSearchBarHeight: $headerLiteHeight + 1px; // border-bottom

$focusBorderColor: #12bbff; // stylelint-disable-line color-no-hex

$suggestionsWrapperMarginBottom: 88px;

@mixin buttonDefault($borderRadius: $spacingXs) {
  color: colors.$foundation-textIconInverted;
  background-color: colors.$brand-backgroundPrimaryActive;
  border: 0;
  border-radius: $borderRadius;

  transition:
    color 0.15s ease-out,
    background-color 0.15s ease-out,
    box-shadow 0.25s ease-out;
}

@mixin buttonHover {
  color: colors.$foundation-textIconInverted;
  background-color: colors.$brand-backgroundPrimary;
}

@mixin buttonFocus {
  color: colors.$foundation-textIconInverted;
  background-color: colors.$brand-backgroundPrimary;
}

@mixin buttonPressed {
  color: colors.$foundation-textIconInverted;
  background-color: colors.$brand-backgroundPrimary;
}

@mixin buttonSelected {
  color: colors.$foundation-textIconInverted;
  background-color: colors.$brand-backgroundPrimary;
}

@mixin buttonDisabled {
  color: colors.$foundation-textIconInverted;
  background-color: $b700;
}

@mixin buttonTabDefault() {
  position: relative;
  overflow: hidden;

  height: 24px;
  min-height: 24px;

  $borderTabThickness: 2px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: $borderTabThickness;
    background-color: colors.$foundation-borderInverted;
    border-top-left-radius: $borderTabThickness;
    border-top-right-radius: $borderTabThickness;
    transform: translateY(100%);
    transition: transform 0.15s ease-out;
  }
}

@mixin buttonTabHover {
  &:after {
    transform: translateY(0);
  }
}

@mixin buttonTabRevert {
  &:after {
    transform: translateY(100%);
  }
}
